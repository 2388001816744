import Application, { _, $ } from 'wgnet-awesomejs';
import pushGaEvent from 'registration/js/lib/googletagmanager/events';
import { showLoginForm } from 'registration/js/lib/get_splittest_flow';
import mobile from 'wgnet-awesomejs/lib/mobile';

import {
    loginRequiredValidator,
    passwordRequiredValidator,
    captchaRequiredValidator,
    ERR_SERVER,
} from 'registration/js/lib/validators';

import Model from './login_form_model';
import BaseRegistrationFormView, { HTTP_CODES_ERROR_MESSAGE_MAPPING } from './registration_form_view';
import { ERROR_BLOCK_EVENTS } from './constants';

const BaseLoginFromView = BaseRegistrationFormView.extend({
    Model,
    basicFormOptions() {
        return {
            view: this,
            featureKeyPrefix: 'Settings.Signin.challenge',
            RiddlerLibPath: 'registration/js/riddler/',
            Url: _.extract('Settings.Signin.urls.basicUrl'),
            challengeKeyHeaderName: _.extract('Settings.Signin.challenge.headerName', 'http_x_wg_challenge_key')
        };
    },
    setValidators() {
        this.model.validators = [
            loginRequiredValidator,
            passwordRequiredValidator,
            captchaRequiredValidator
        ];
    },
    setValidFields(errors) {
        BaseRegistrationFormView.prototype.setValidFields.call(this, errors);
        if (!mobile().useMobile) {
            this.$form.trigger('__all__.valid');
        }
    },
    errorGaEvent(error) {
        pushGaEvent('VALIDATION', 'login', error);
    },
    focusInput(event) {
        const $fieldset = $(event.target).parents('fieldset').first();
        pushGaEvent('LOGIN_FORM_INTERACTION', `${event.target.name}Focus`);
        $fieldset.addClass('active');
    },
    bindElements() {
        this.$form = this.$('.js-registration-login-form');
        this.$throbbingElement = this.$('.js-registration-throbbing-element');
        this.delegateEvents({
            'submit form': 'registrationStart',
            'focus input': 'focusInput',
            'blur input': 'blurInput',
            'keyup input': 'formPrevalidate',
            'change input': 'formPrevalidate',
            'login.edit.enabled': 'setValidators',
            'login.edit.disabled': _.bind(this.setValidators, this, { loginDisable: true }),
            'suggestion.login': 'setSuggestionValue',
            '__all__.error': 'showErrorBlock',
            '__all__.valid': 'hideErrorBlock',
        });
    },
    showErrorBlock(event, error) {
        Application.trigger(ERROR_BLOCK_EVENTS.show, error);
    },
    hideErrorBlock() {
        Application.trigger(ERROR_BLOCK_EVENTS.hide);
    },
    getErrors(result) {
        const errorCodes = _.extract(result, 'responseJSON.errors_codes', {});
        const errors = _.extract(result, 'responseJSON.errors', {});
        if (typeof errors === 'string') {
            return {
                __all__: [errors]
            };
        }
        let usernameError = {};
        if (errorCodes && errorCodes.username) {
            usernameError = { login: errorCodes.username };
        }
        return Object.assign({}, errors, errorCodes, usernameError);
    },
    onRegistrationError(result = {}, prevalidate) {
        const statusCode = result.status;
        const errorMessage = HTTP_CODES_ERROR_MESSAGE_MAPPING[statusCode];
        try {
            result.responseJSON = result.responseJSON || JSON.parse(result.responseText);
        } catch (err) {
            this.stopWait();
            this.$form.trigger('__all__.error', { errors: [ERR_SERVER] });
            return;
        }

        this.stopWait();
        if (statusCode === 403) {
            window.location.href = window.location.href;
            return;
        }
        if (errorMessage) {
            this.$form.trigger('__all__.error', { errors: [errorMessage] });
            return;
        }

        const errors = this.getErrors(result);
        const keys = this.getErrorKeys(errors);

        this.setValidFields(errors);

        if (keys.length > 0) {
            keys.forEach(key => {
                const error = errors[key];
                if (!error) {
                    return;
                }
                if (!prevalidate && showLoginForm()) {
                    this.errorGaEvent(`${key} ${error[0]}`);
                    if ((key === 'login' || key === 'password') && error[0] !== 'required') {
                        this.$form.trigger('__all__.error', { errors: ['invalid_credentials'] });
                        return;
                    }
                }
                if (key === '__all__' && error[0] === 'already_authenticated') {
                    this.redirectTo(result.responseJSON.extras.next_url);
                    return;
                }
                if (key === '__all__' && error[0] === 'conflict_credentials') {
                    this.redirectTo(result.responseJSON.extras.next_url);
                    return;
                }
                if (key === '__all__' && error[0] === 'challenge_invalid') {
                    this.$form.trigger('captcha.error', {
                        errors: ['invalid'],
                        field: 'captcha',
                        prevalidate
                    });
                    return;
                }
                this.$form.trigger(`${key}.error`, {
                    errors: error,
                    field: key,
                    prevalidate
                });
            });
            if (!prevalidate) {
                this.focusOnError(keys);
            }
        } else {
            this.$form.trigger('__all__.error', { errors: ['error_unknown'] });
        }
    },
});

export default BaseLoginFromView;
